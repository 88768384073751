<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      v-if="canShow(item.modulePermission, item.isCollapse)"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },
  methods: {
    canShow(requiredPermissionsModule, isCollapse) {
      if (requiredPermissionsModule === null) {
        return true;
      } else {
        const rootKeys = Object.keys(requiredPermissionsModule);
        const permissions = this.globalPermission;
        const check = rootKeys.map((key) => {
          const primaryKey = Object.keys(requiredPermissionsModule[key]);
          const rootPermission = permissions[key];
          if (rootPermission) return rootPermission[primaryKey];
        });
        if (!isCollapse) {
          return check.every((ele) => ele);
        } else {
          return check.some((ele) => ele);
        }
      }
    },
  },
};
</script>

<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <locale />

    <b-navbar-nav class="nav align-items-center ml-auto">
      <NotificationsComponent
        v-if="
          this.globalPermission.notifications &&
          this.globalPermission.notifications.index
        "
      />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData && userData.full_name }}
            </p>
            <span class="user-status">{{ userData && userData.role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="profileImage || userImage"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'profile' }"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("g.profile") }}</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("g.logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import NotificationsComponent from "@/components/notificationComponent";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Locale from "../../@core/layouts/components/app-navbar/components/Locale.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale,
    DarkToggler,
    NotificationsComponent,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      new_avatar: null,
      userImage: require("@/assets/images/pages/dashboard/user.png") ,
    };
  },

  computed: {
    profileImage() {
      if (this.userData) {
        return (
          this.userData.media?.length > 0 &&
          this.userData?.media?.filter((item) => item?.name === "avatar")[0]
            ?.path
        );
      }
    },
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Good Bye`,
          icon: "CheckCircleIcon",
          variant: "warning",
          text: `You have successfully logged out!`,
        },
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    },
  },
};
</script>

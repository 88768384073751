export default [
  {
    title: "g.dashboard",
    route: "home",
    icon: "dashboard.svg",
    iconActive: "dashboard-fill.svg",
    modulePermission: { statistics: { index: true } },
  },
  {
    title: "g.users",
    route: "users",
    icon: "users.svg",
    iconActive: "users-fill.svg",
    modulePermission: { users: { index: true } },
  },
  {
    title: "g.vendors",
    route: "vendors",
    icon: "store.svg",
    iconActive: "store-fill.svg",
    modulePermission: { vendors: { index: true } },
  },
  {
    title: "g.brands",
    route: "brands",
    icon: "brand.svg",
    iconActive: "brand-fill.svg",
    modulePermission: { brands: { index: true } },
  },
  {
    title: "g.categories",
    route: "categories",
    icon: "categories.svg",
    iconActive: "categories-fill.svg",
    modulePermission: { categories: { index: true } },
  },
  {
    title: "g.catalog",
    route: "CatalogList",
    icon: "catalog.svg",
    iconActive: "catalog-fill.svg",
    modulePermission: {
      catalogs: { index: true },
    },
  },
  {
    title: "g.products",
    route: "products",
    icon: "product.svg",
    iconActive: "product-fill.svg",
    modulePermission: {
      products: { index: true },
    },
  },
  {
    title: "reviews.reviews",
    route: "reviews-list",
    icon: "star.svg",
    iconActive: "star-fill.svg",
    modulePermission: { reviews: { index: true } },
  },
  {
    title: "coupons.coupons",
    route: "couponsList",
    icon: "coupon.svg",
    iconActive: "coupon-fill.svg",
    modulePermission: { coupons: { index: true } },
  },
  {
    title: "messages.contact_us",
    route: "messages",
    icon: "messages.svg",
    iconActive: "messages-fill.svg",
    modulePermission: { contacts: { index: true } },
  },
  {
    title: "delivery.delivery",
    icon: "delivery.svg",
    iconActive: "delivery-fill.svg",
    isCollapse: true,
    modulePermission: {
      "delivery-boys": { index: true },
      shifts: { index: true },
    },

    children: [
      {
        title: "delivery.delivery_boys",
        route: "delivery-boys-list",
        icon: "delivery-boys.svg",
        iconActive: "delivery-boys-fill.svg",
        modulePermission: { "delivery-boys": { index: true } },
      },
      {
        title: "delivery.delivery_requests",
        route: "delivery-requests-list",
        icon: "delivery-requests.svg",
        iconActive: "delivery-requests-fill.svg",
        modulePermission: { "delivery-boys": { index: true } },
      },
      {
        title: "delivery.delivery-shifts",
        route: "DeliveryShiftsList",
        icon: "calendar-event-line.svg",
        iconActive: "calendar-event-fill.svg",
        modulePermission: { shifts: { index: true } },
      },
    ],
  },
  {
    title: "notifications.notifications",
    route: "notifications",
    icon: "notification.svg",
    iconActive: "notification-fill.svg",
    modulePermission: { notifications: { index: true } },
  },
  {
    title: "orders.orders",
    route: "orders-list",
    icon: "orders.svg",
    iconActive: "orders-fill.svg",
    modulePermission: { orders: { index: true } },
  },
  {
    title: "payment_methods.payment_methods",
    route: "payment-methods",
    icon: "payment.svg",
    iconActive: "payment-fill.svg",
    modulePermission: { "payment-methods": { show: true } },
  },
  {
    title: "static_pages.static_pages",
    route: "staticPage",
    icon: "terms.svg",
    iconActive: "terms-fill.svg",
    modulePermission: { pages: { index: true } },
  },
  {
    title: "faq.faqs",
    route: "FAQList",
    icon: "faq.svg",
    iconActive: "faq-fill.svg",
    modulePermission: { FAQs: { index: true } },
  },
  {
    title: "g.settings",
    icon: "settings.svg",
    isCollapse: true,
    iconActive: "settings-fill.svg",
    modulePermission: {
      settings: { index: true },
      sliders: { index: true },
      countries: { index: true },
      roles: { index: true },
    },

    children: [
      {
        title: "g.GeneralSetting",
        route: "settings",
        icon: "generalSettings.svg",
        iconActive: "generalSettingsActive.svg",
        modulePermission: { settings: { index: true } },
      },
      {
        title: "sliders.sliders",
        route: "slidersList",
        icon: "slider.svg",
        iconActive: "slider-fill.svg",
        modulePermission: { sliders: { index: true } },
      },
      {
        title: "g.EmailSetting",
        route: "emailSetting",
        icon: "emailSettings.svg",
        iconActive: "emailSettings-fill.svg",
        modulePermission: { settings: { index: true } },
      },
      {
        title: "g.countries",
        route: "countries",
        icon: "countries.svg",
        iconActive: "countries-fill.svg",
        modulePermission: { countries: { index: true } },
      },
      {
        title: "g.roles",
        route: "list-roles",
        icon: "roles.svg",
        iconActive: "roles-fill.svg",
        modulePermission: { roles: { index: true } },
      },
      {
        title: "g.vendor_roles",
        route: "list-vendor-roles",
        icon: "roles.svg",
        iconActive: "roles-fill.svg",
        modulePermission: { roles: { index: true } },
      },
      {
        title: "platforms.platforms_state",
        route: "platforms-list",
        icon: "platforms.svg",
        iconActive: "platforms-fill.svg",
        modulePermission: { settings: { index: true } },
      },
      {
        title: "g.platformInfo",
        icon: "info.svg",
        iconActive: "info-fill.svg",
        route: "socialLinksSettings",
        modulePermission: { settings: { index: true } },
      },
    ],
  },
];
